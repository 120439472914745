<template>
  <el-card class="box-card">
    <el-row>
      <el-descriptions class="margin-top" title="" :column="3">
        <el-descriptions-item label="团长信息">{{ row.user_id }}</el-descriptions-item>
        <el-descriptions-item label="商品信息">{{ row.name }}</el-descriptions-item>
        <el-descriptions-item label="拼团价格">{{ row.price }}</el-descriptions-item>
        <el-descriptions-item label="开团时间">{{ row.launch_at | formatDate }}</el-descriptions-item>
        <el-descriptions-item label="成团人数 / 当前人数">{{ row.group_size }} / {{ row.current_number }}</el-descriptions-item>
        <el-descriptions-item label="是否虚拟成团">
          <span v-if="row.virtual_clustering == '1'">是</span>
          <span v-else-if="row.virtual_clustering == '0'">否</span>
        </el-descriptions-item>
        <el-descriptions-item label="结束时间">{{ row.end_at | formatDate }}</el-descriptions-item>
        <el-descriptions-item label="砍价状态">
          <span v-if="row.state == '0'">拼团成功</span>
          <span v-else-if="row.state == '1'">拼团失败</span>
        </el-descriptions-item>
      </el-descriptions>
    </el-row>
    <el-table
        :data="list"
        @selection-change="setSelectRows">
      <el-table-column
          align="center"
          prop="user_id"
          label="参与人">
      </el-table-column>
      <el-table-column align="right" label="" show-overflow-tooltip>
        <template #default="{ row }">
          <el-image v-if="imgShow" :src="row.pic" class="commodity_pic"/>
        </template>
      </el-table-column>
      <el-table-column
          align="left"
          prop="name"
          label="商品">
      </el-table-column>
      <el-table-column
          align="center"
          prop="order_sn"
          label="订单号">
      </el-table-column>
      <el-table-column
          align="center"
          label="下单时间"
          prop="create_at"
          show-overflow-tooltip
      >
        <template slot-scope="scope">
          <div>{{ scope.row.create_at | formatDate }}</div>
        </template>
      </el-table-column>
      <el-table-column
          align="center"
          prop="income"
          label="下单金额">
      </el-table-column>
      <el-table-column
          align="center"
          prop="state_order"
          :formatter="formatterStateOrder"
          label="订单状态">
      </el-table-column>
      <el-table-column align="center" label="操作" width="100">
        <template #default="{ row }">
          <el-button type="text" @click="handleOrder(row)">查看订单</el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
        background
        :current-page="queryForm.page"
        :layout="layout"
        :page-size="queryForm.pageSize"
        :total="count"
        @current-change="handleCurrentChange"
        @size-change="handleSizeChange"
    />
    <Edit ref="Edit"/>
  </el-card>
</template>
<script>
import {formatDate} from "@/utils/theTimeStamp";
import Edit from './components/Edit'

export default {
  // 时间戳转换
  filters: {
    formatDate(time) {
      time = time * 1000
      let date = new Date(time)
      // console.log(new Date(time))
      return formatDate(date, 'yyyy-MM-dd hh:mm:ss')
    },
  },
  components: {Edit},
  props: [],
  data() {
    return {
      selectRows: '',
      imgShow: true,
      // listLoading: true,
      layout: 'total, sizes, prev, pager, next, jumper',
      count: 0,
      queryForm: {
        page: 1,
        pageSize: 10,
      },
      row: {
        user_id: '111',
        name: '111',
        price: '111',
        launch_at: '1647584613',
        group_size: '2341',
        current_number: '23',
        virtual_clustering: '0',
        end_at: '1647584613',
        state: '1',
      },
      list: [
        {
          id: '1',
          name: '益力多',
          pic: 'https://img2.baidu.com/it/u=1814268193,3619863984&fm=253&fmt=auto&app=138&f=JPEG?w=632&h=500',
          sale_price: '100',
          stock: '999',
          sales: '1',
          state: '0',
          create_at: '1647584613',
          merchant_id: '1',
          is_check: '0',
        },
        {
          id: '2',
          name: '益力多',
          pic: 'https://img2.baidu.com/it/u=1814268193,3619863984&fm=253&fmt=auto&app=138&f=JPEG?w=632&h=500',
          sale_price: '100',
          stock: '999',
          sales: '1',
          state: '0',
          create_at: '1647584613',
          merchant_id: '1',
          is_check: '1',
        },
        {
          id: '3',
          name: '益力多',
          pic: 'https://img2.baidu.com/it/u=1814268193,3619863984&fm=253&fmt=auto&app=138&f=JPEG?w=632&h=500',
          sale_price: '100',
          stock: '999',
          sales: '1',
          state: '1',
          create_at: '1647584613',
          merchant_id: '1',
          is_check: '2',
        },
      ],
    }
  },
  computed: {},
  watch: {},
  created() {
    this.fetchData()
  },
  methods: {
    handleOrder(row) {
      console.log(row)
    },
    setSelectRows(val) {
      this.selectRows = val
    },
    handleSizeChange(val) {
      this.queryForm.pageSize = val
      this.fetchData()
    },
    handleCurrentChange(val) {
      this.queryForm.page = val
      this.fetchData()
    },
    queryData() {
      this.queryForm.page = 1
      this.fetchData()
    },
    async fetchData() {
      this.listLoading = true
      const {data} = await getList(this.queryForm)
      this.list = data.list
      this.count = data.count
      this.count = parseInt(data.count)
      if (!this.list.length) this.queryForm.page++
      this.listLoading = false
    },
    // 状态
    formatterStateOrder: function (row) {
      return row.state_order == '0' ? '已下单'
          : row.state == '1' ? '已结束'
              : row.state == '2' ? '已评价'
                  : row.state == '3' ? '已退款'
                      : row.state == '4' ? '已退货'
                          : row.state == '-1' ? '已接受'
                              : '暂无'
    },
  }
}

</script>
<style>
.commodity_pic {
  width: 60px;
  height: 60px;
}

.el-pagination {
  text-align: center;
  margin-top: 20px;
}

.el-form-item {
  margin-bottom: 0;
}

.el-dropdown {
  margin-right: 10px;
}

.page {
  background-color: #FFF;
  padding: 20px;
  margin: 20px;
}

.el-row {
  margin-bottom: 15px;
}
</style>
